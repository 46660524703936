import Vue from "vue";

export default {
  getSessionToken() {
    return Vue.$cookies.get("poolfit_admin_session");
  },

  getSessionHeaders() {
    let options = {};
    let token = this.getSessionToken();

    if (token !== null) {
      options.headers = { "X-Access-Token": token };
    }

    return options;
  },

  deleteSession() {
    Vue.$cookies.remove("sessionToken");
  },

  validateSession() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/customers/profile";
    let options = this.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  login(loginCredentials) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/auth/login";

    return Vue.axios.post(apiMethodUrl, loginCredentials);
  },

  logout() {
    this.deleteSession();
  }
};
